import React from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import { Fade } from 'wui/Fade';
import { InputDialog } from 'wui/InputDialog';
import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { Tabs } from 'wui/Tabs';
import { List } from 'wui/List';
import { Typography } from 'wui/Typography';
import { AvatarSize, TabsAlignment, TabsVariant, TabItem } from 'wix-ui-tpa';

import { selectHasReactions, selectReactionsMap } from './selectors';

import { ProfileListItem } from '../../ProfileListItem';
import { getReactionIcon, toReactionType } from '../Reactions/helpers';
import { Box } from 'wui/Box';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  legacy?: boolean;
  item: IFeedItem;
}

export function ReactedMembersDialog(props: IProps) {
  const { legacy, item, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const feedItemId = item.feedItemId as string;

  const reactions = useSelector(selectReactionsMap(feedItemId));
  const hasReactions = useSelector(selectHasReactions(feedItemId));

  const [activeTab, setActiveTab] = React.useState('total');

  if (!hasReactions) {
    return null;
  }

  const tabs = [getTotalTab(), ...getSummaryTabs()];

  const tabReactions =
    activeTab === 'total'
      ? item.reactions.reactions
      : reactions[activeTab] || [];

  return (
    <InputDialog {...rest}>
      <DialogTitle />
      <Box padding={isMobile ? 'SP0' : 'SP0 SP6'}>
        <Tabs
          bw
          items={tabs}
          value={activeTab}
          alignment={TabsAlignment.left}
          variant={TabsVariant.fit}
          onChange={handleTabChange}
        />
      </Box>
      <DialogContent>
        <SwitchTransition>
          <Fade key={activeTab}>
            <List disablePadding>
              {tabReactions.map((reaction) => (
                <ProfileListItem
                  disableGutters
                  profile={reaction.user}
                  data-hook="reacted member"
                  key={reaction.user?.memberId}
                  avatarProps={{
                    size: isMobile ? AvatarSize.medium : AvatarSize.large,
                  }}
                >
                  <Typography variant="p2-20">
                    {getIcon(reaction.code, 20)}
                  </Typography>
                </ProfileListItem>
              ))}
            </List>
          </Fade>
        </SwitchTransition>
      </DialogContent>
    </InputDialog>
  );

  function getIcon(code: string, size?: number) {
    return legacy ? code : getReactionIcon(toReactionType(code)!, size) || code;
  }

  function handleTabChange(id: string) {
    setActiveTab(id);
  }

  function getTotalTab(): TabItem {
    return {
      id: 'total',
      title: t('groups-web.discussion.feed.reactions.all', {
        count: item.reactions.total,
      }),
    };
  }

  function getSummaryTabs(): TabItem[] {
    return Object.keys(reactions).map((code) => {
      const userReactions = reactions[code];

      return {
        id: code,
        prefix: getIcon(code, 16),
        title: userReactions.length as unknown as string,
      };
    });
  }
}

ReactedMembersDialog.displayName = 'ReactedMembersDialog';
