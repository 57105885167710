import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { DraftContent } from 'ricos-content';
import type { RichContent } from 'ricos-schema';
import type { RicosEditorType } from 'ricos-editor';
import { safeJsonParse } from 'wix-rich-content-common';
import { EditorEventsContext } from 'wix-rich-content-editor-common/libs/EditorEventsContext';

import type { IFeedItem } from 'api/feed/types';
import type { ITopic } from 'api/topics/types';
import {
  selectCurrentUser,
  selectDidItemChange,
  selectDidItemCreate,
  selectDraft,
  selectGroupName,
  selectGroupSlugById,
  selectIsItemCreating,
  selectIsItemUpdating,
} from 'store/selectors';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Button } from 'wui/Button';
import { InputDialog } from 'wui/InputDialog';
import { ButtonGroup } from 'wui/ButtonGroup';
import { DialogFooter } from 'wui/DialogFooter';
import { DialogContent } from 'wui/DialogContent';
import { DialogMobileHeader } from 'wui/DialogMobileHeader';
import { CardHeader } from 'wui/CardHeader';
import { EmptyState } from 'wui/EmptyState';
import { Spinner } from 'wui/Spinner';
import { Avatar, AvatarSize, TextButton, TextButtonPriority } from 'wix-ui-tpa';

import { RichContentEditor } from 'Group/Widget/RichContent/loadable';

import { Link } from '../Link';
import { ProfileLink } from '../ProfileLink';
import { TopicsSelect } from './TopicsSelect';
import {
  FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON,
  FEED_ITEM_EDIT_DIALOG_OK_BUTTON,
  FEED_ITEM_EDIT_DIALOG_ROOT,
} from './dataHooks';

import { classes } from './FeedItemEditorDialog.st.css';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  promoteGroup?: boolean;
  groupId: string;
  item?: IFeedItem;
  forTopic?: ITopic;

  cancelLabel?: string;

  onCancel?(): void;
  onRefChange?(node: RicosEditorType | null): void;
}

export function FeedItemEditorDialog(props: IProps) {
  const {
    item,
    groupId,
    forTopic,
    onClose,
    onCancel,
    className,
    cancelLabel,
    onRefChange,
    promoteGroup,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { feed$ } = useController();

  const $ricos = React.useRef<RicosEditorType | null>(null);
  const editor = React.useContext(EditorEventsContext);

  const [isValid, setIsValid] = useState(false);
  const [focusTrap, setFocusTrap] = React.useState(true);
  const [isEditorBusy, setIsEditorBusy] = React.useState(false);
  const [topics, setTopics] = React.useState(
    item?.entity.topics || (forTopic ? [forTopic] : []),
  );

  const feedItemId = item?.feedItemId;
  const contentJSON = item?.entity?.body?.content;
  const isNewPost = !feedItemId;

  const user = useSelector(selectCurrentUser);
  const isCreating = useSelector(selectIsItemCreating);
  const didCreate = useSelector(selectDidItemCreate);
  const isUpdating = useSelector(selectIsItemUpdating(feedItemId));
  const didUpdate = useSelector(selectDidItemChange(feedItemId));
  const groupName = useSelector(selectGroupName(groupId));
  const slug = useSelector(selectGroupSlugById(groupId));
  const draft = useSelector(selectDraft);
  const isLoading = isCreating || isUpdating;

  const content = React.useMemo(() => {
    return contentJSON
      ? (safeJsonParse(contentJSON) as DraftContent)
      : undefined;
  }, [contentJSON]);

  // Wix OOI ¯\_(ツ)_/¯
  useDidUpdate(() => {
    if (item?.feedItemId) {
      if (didUpdate) {
        props.onClose();
      }

      return;
    }

    if (didCreate) {
      setTopics([]);
      feed$.updateDraft(undefined);
      props.onClose();
    }
  }, [didUpdate, didCreate]);

  return (
    <InputDialog
      onClose={handleClose}
      focusTrap={focusTrap}
      hideCloseButton={isMobile}
      paperProps={{
        className: classes.paper,
        'data-hook': FEED_ITEM_EDIT_DIALOG_ROOT,
      }}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      <Show if={isMobile}>
        <DialogMobileHeader>
          <TextButton
            onClick={handleCancel}
            data-hook={FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON}
            priority={TextButtonPriority.secondary}
          >
            {cancelLabel || t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <TextButton
            onClick={handleSubmit}
            data-hook={FEED_ITEM_EDIT_DIALOG_OK_BUTTON}
            disabled={isEditorBusy || !isValid || isLoading}
            priority={TextButtonPriority.primary}
          >
            {isLoading ? (
              <Spinner bw size={20} />
            ) : (
              t('groups-web.discussion.new-post.mobile.post')
            )}
          </TextButton>
        </DialogMobileHeader>
      </Show>

      <Hide if={isMobile}>
        <CardHeader
          verticalAlign="middle"
          avatar={
            <ProfileLink profile={user}>
              <Avatar
                lettersLimit={2}
                name={user.name}
                src={user.imageUrl}
                size={AvatarSize.large}
              />
            </ProfileLink>
          }
          title={<ProfileLink profile={user} />}
          titleProps={{ variant: 'p2-16' }}
          subtitle={
            !promoteGroup ? undefined : (
              <Trans
                values={{ groupName }}
                i18nKey="groups-web.discussion.new-post.create-post-in-group"
                components={[
                  <span />,
                  <Link state="group" params={{ slug }} />,
                ]}
              />
            )
          }
        />
      </Hide>

      <DialogContent
        scrollable={false}
        className={classes.content}
        disableSideGutters={isMobile}
      >
        <RichContentEditor
          bw
          autoFocus
          groupId={groupId}
          content={isNewPost ? draft : content}
          onChange={validate}
          usage="NewPostModal"
          ref={handleEditorRef}
          contentId={item?.feedItemId}
          className={classes.editor}
          onBusyChange={setIsEditorBusy}
          placeholder={t('groups-web.discussion.new-post.placeholder')}
          fallback={<EmptyState bw variant="section" title={<Spinner bw />} />}
          modalSettings={{
            onModalOpen: handleRicosModalOpen,
            onModalClose: handleRicosModalClose,
          }}
        />
      </DialogContent>

      <Hide if={isMobile}>
        <DialogFooter align="space-between" verticalAlign="middle">
          <TopicsSelect
            groupId={groupId}
            value={topics}
            onChange={setTopics}
            isNewPost={isNewPost}
          />
          <ButtonGroup>
            <Button
              bw
              secondary
              data-hook={FEED_ITEM_EDIT_DIALOG_CANCEL_BUTTON}
              onClick={handleCancel}
            >
              {cancelLabel || t('groups-web.discussion.new-post.cancel')}
            </Button>
            <Button
              bw
              loading={isLoading}
              onClick={handleSubmit}
              data-hook={FEED_ITEM_EDIT_DIALOG_OK_BUTTON}
              disabled={isEditorBusy || !isValid}
            >
              {t('groups-web.discussion.new-post.publish')}
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </Hide>
    </InputDialog>
  );

  function validate() {
    const helpers = $ricos.current?.getContentTraits();
    const isEmpty = helpers ? helpers.isEmpty : true;
    const isChanged = isNewPost ? true : !!helpers?.isContentChanged;

    setIsValid(!isEmpty && isChanged);
  }

  function handleRicosModalOpen() {
    setFocusTrap(false);
  }

  function handleRicosModalClose() {
    setFocusTrap(true);
  }

  function handleEditorRef(node: RicosEditorType | null) {
    onRefChange?.(node);
    $ricos.current = node;
  }

  async function handleSubmit() {
    const content = await editor.publish();

    const topicIds = topics.map((topic) => topic.id as string);

    if (item?.feedItemId) {
      feed$.update(groupId, item.feedItemId, JSON.stringify(content), topicIds);
    } else {
      feed$.create(groupId, JSON.stringify(content), topicIds);
    }
  }

  function handleCancel() {
    setTopics([]);
    feed$.updateDraft(undefined);

    props.onCancel ? props.onCancel() : props.onClose();
  }

  async function handleClose() {
    const content = await $ricos.current?.getContent();

    if (isNewPost) {
      feed$.updateDraft(content);
    }

    props.onClose();
  }
}

FeedItemEditorDialog.displayName = 'FeedItemEditorDialog';
