import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ButtonPriority, Text, TextTypography } from 'wix-ui-tpa';
import cls from 'classnames';

import { Box, BoxProps } from '../Box/Box';
import { Button } from '../Button';

import { classes, st } from './EmptyState.st.css';

export interface EmptyStateProps {
  title?: string;
  transparent?: boolean;
  content: React.ReactNode;
  className?: string;
  button?:
    | React.ReactElement
    | {
        label: string;
        onClick(): void;
      };
  dataHook?: string;
  boxProps?: BoxProps;
}

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { title, content, button, dataHook, boxProps, transparent } = props;
  const { isMobile } = useEnvironment();

  const renderButton = () => {
    return React.isValidElement(button) ? (
      button
    ) : (
      <Button
        priority={ButtonPriority.primary}
        onClick={
          (button as {
            label: string;
            onClick(): void;
          })!.onClick
        }
      >
        {
          (button as {
            label: string;
            onClick(): void;
          })!.label
        }
      </Button>
    );
  };

  return (
    <Box
      className={cls(st(classes.root, {}, props.className), {
        [classes.transparent]: transparent,
      })}
      withSideBorders={!isMobile}
      data-hook={dataHook}
      {...boxProps}
    >
      {title && (
        <Text typography={TextTypography.smallTitle} className={classes.text}>
          {title}
        </Text>
      )}
      <Text
        typography={TextTypography.listText}
        className={cls(classes.content, classes.text)}
        tagName="p"
      >
        {content}
      </Text>
      {button ? renderButton() : null}
    </Box>
  );
};
