import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, useBi, useEnvironment } from '@wix/yoshi-flow-editor';

import { followMember } from '@wix/bi-logger-groups/v2';
import type { IGroupMember, IMember } from 'api/members/types';

import {
  ButtonProps,
  TextButtonPriority,
  Spinner,
  TextButton,
  ButtonSize,
} from 'wix-ui-tpa';

import { UISref } from 'common/router';
import { useController } from 'common/context/controller';

import { Button } from 'wui/Button';

import { ProfileLink } from '../ProfileLink';
import { PrivateProfileHint } from '../PrivateProfileHint';

import { selectCurrentUser, selectFollowStatus } from 'store/selectors';

interface FollowButtonProps extends ButtonProps {
  bw?: boolean;
  useTextButton?: boolean;
  profile: IMember | IGroupMember;

  // used for BI
  groupId?: string;
}

export const FollowButton: React.FC<FollowButtonProps> = (props) => {
  const { profile, useTextButton, groupId, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { application$ } = useController();
  const user = useSelector(selectCurrentUser);
  const status = useSelector(selectFollowStatus(profile.memberId as string));

  const isCurrentUser = user.siteMemberId === profile.memberId;
  const isFollowing = user.following.includes(profile.memberId as string);

  const bi = useBi();

  const label = isFollowing
    ? t('groups-web.following')
    : t('groups-web.follow');

  if (isCurrentUser) {
    if (useTextButton) {
      return (
        <ProfileLink secondary={false} profile={profile}>
          {t('groups-web.my-profile')}
        </ProfileLink>
      );
    }

    return (
      <UISref state="members.profile" params={{ memberId: profile.memberId }}>
        <Button
          as="a"
          secondary
          size={isMobile ? ButtonSize.tiny : ButtonSize.medium}
          {...rest}
        >
          {t('groups-web.my-profile')}
        </Button>
      </UISref>
    );
  }

  if (useTextButton) {
    return (
      <PrivateProfileHint disabled={!profile.isPrivate}>
        <TextButton
          {...rest}
          onClick={handleClick}
          priority={TextButtonPriority.primary}
          disabled={status.loading || !profile.memberId}
        >
          {status.loading ? <Spinner diameter={20} /> : label}
        </TextButton>
      </PrivateProfileHint>
    );
  }

  return (
    <PrivateProfileHint disabled={!profile.isPrivate}>
      <Button
        onClick={handleClick}
        secondary={isFollowing}
        loading={status.loading}
        size={isMobile ? ButtonSize.tiny : ButtonSize.medium}
        {...rest}
      >
        {label}
      </Button>
    </PrivateProfileHint>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    if (!user.loggedIn) {
      application$.promptLogin();
      return;
    }

    isFollowing
      ? application$.unfollow(profile.memberId as string)
      : application$.follow(profile.memberId as string);

    bi.report(
      followMember({
        group_id: groupId,
        site_member_id: profile.memberId,
        origin: 'discussion_tab_link_clk',
        action: isFollowing ? 'unfollow' : 'follow',
      }),
    );
  }
};

FollowButton.displayName = 'FollowButton';
